
<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card >
        <vs-popup classContent="popup-example" title="Nouvelle ville" :active.sync="popupVille">

          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-select autocomplete label="Pays" class="w-full" v-model="pays" >
                <vs-select-item :key="index" :value="item.pays" :text="item.pays" v-for="(item, index) in paysData" />
              </vs-select>
            </div>
            <div class="vx-col w-full">
              <vs-input class="w-full" type="text" label="Libelle" v-validate="'required'" name="libelleVille" v-model="libelleVille" />
              <span class="text-danger text-sm"  v-show="errors.has('libelleVille')">{{ errors.first('libelleVille') }}</span>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col sm:w-2/3 w-full ml-auto">
              <vs-button class="mr-3 mb-2" @click="creatPays" :disabled="!validateForm">Enregistrer</vs-button>
              <vs-button color="warning" type="border" class="mb-2" @click="libelleVille = ''; ">Annuler</vs-button>
            </div>
          </div>
        </vs-popup>
        <vs-popup classContent="popup-example" title="Modifier une ville" :active.sync="popupVilleUpdate">
          <div class="vx-row mb-6">
            <div class="vx-col w-full">

              <vs-select autocomplete label="Pays" class="w-full" v-model="pays" >
                  <vs-select-item v-for="(item, index) in paysData" :key="index" :value="item.pays" :text="item.pays"/>
              </vs-select>
            </div>
            <div class="vx-col w-full">
              <vs-input class="w-full" type="text" label="Libelle" v-validate="'required'" name="libelleVille" v-model="libelleVille" />
              <span class="text-danger text-sm"  v-show="errors.has('libelleVille')">{{ errors.first('libelleVille') }}</span>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-2/3 w-full ml-auto">
              <vs-button class="mr-3 mb-2" @click="updateVille" :disabled="!validateForm">Modifier</vs-button>
              <vs-button color="warning" type="border" class="mb-2" @click="libelleVille = '';  popupVilleUpdate = false;">Annuler</vs-button>
            </div>
          </div>
        </vs-popup>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table noDataText="Aucune donnée" v-model="selected" stripe pagination max-items="10" search :data="villeData">
              <template slot="header">
                <!-- ACTION - DROPDOWN -->
                <vs-dropdown vs-trigger-click class="cursor-pointer">

                  <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                    <span class="mr-2 leading-none">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>

                  <vs-dropdown-menu>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Supprimer</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Print</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>CSV</span>
                      </span>
                    </vs-dropdown-item>

                  </vs-dropdown-menu>
                </vs-dropdown>
                <vs-button class="mb-4 ml-5 md:mb-0" @click="popupVille=true">Nouvelle ville</vs-button>
              </template>
              <template slot="thead">
                <vs-th sort-key="_id">
                  N°
                </vs-th>
                <vs-th sort-key="pays">
                  Pays
                </vs-th>
                <vs-th sort-key="libelleVille">
                  Ville
                </vs-th>
                <vs-th sort-key="website">
                  Action
                </vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                  <vs-td :data="data[indextr]">
                    {{indextr + 1}}
                  </vs-td>

                  <vs-td :data="data[indextr].pays">
                    {{data[indextr].pays}}
                  </vs-td>

                  <vs-td :data="data[indextr].libelleVille">
                    {{data[indextr].libelleVille}}
                  </vs-td>

                  <vs-td :data="data[indextr].id">
                    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="updateVilleShowForm(data[indextr])" />
                      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(data[indextr])" />
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      popupVille: false,
      popupVilleUpdate: false,
      pays: '',
      libelleVille: '',
      selected:[],
      villes:[],
      villeId: ''
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.pays !== '' && this.libelleVille !== ''
    },
    villeData () {
      return this.$store.state.pays.villes
    },
    paysData () {
      return this.$store.state.pays.pays
    }
  },

  methods: {
    creatPays () {
      const payload = {
        pays: this.pays,
        libelleVille: this.libelleVille
      }

      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('pays/createVille', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success !',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.resete_data()
          this.$vs.loading.close()
          this.popupVille = false
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    confirmDeleteRecord (data) {
      this.villeId = data._id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmé la Suppression',
        text: `Vous allez supprimer la ville "${data.libelleVille}"`,
        acceptText: 'Supprimer',
        accept: this.deleteRecord
      })
    },
    deleteRecord () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('pays/dropVille', this.villeId)
        .then(() => {
          this.villeId = ''
          this.showDeleteSuccess()
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'danger',
        title: 'Supprimer',
        text: 'La ville selectionnée a bien été supprimée'
      })
    },
    getVilles () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('pays/getVilles')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    getPays () {
      this.$store.dispatch('pays/getPays')
        .then(() => { })
        .catch((err) => { console.log(err) })
    },
    updateVilleShowForm (data) {
      this.pays = data.pays
      this.libelleVille = data.libelleVille
      this.villeId = data._id
      this.popupVilleUpdate = true
    },
    updateVille () {
      const payload = {
        pays: this.pays,
        libelleVille:this.libelleVille,
        id: this.villeId
      }

      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('pays/updateVille', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success !',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.resete_data()
          this.$vs.loading.close()
          this.popupVilleUpdate = false
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    resete_data () {
      this.pays = this.libelleVille = ''
    }
  },
  created () {
    if (!(this.$store.state.pays.villes.length > 0)) this.getVilles()
    if (!(this.$store.state.pays.pays.length > 0)) this.getPays()
  }
}
</script>

